
html, body {
  background-color: #12232e;
  font-family: Roboto, 'Roboto' !important;
  color: #FFF;
  overflow-x: hidden;
  padding: 0px !important;
  margin: 0;
}

.dynHeader {
  font-size: 2.4em !important;
}

.Underline {
  border-bottom: 2px solid #f57d05;
}

.MuiAppBar-colorPrimary {
  background-color: transparent !important;
}

.reportBG {
  max-width: 100%;
  opacity: 0.3;
  animation: opacity 5s infinite alternate-reverse;
}

@keyframes opacity {
  0% {
    opacity: 0.1;
  }
  100% {
    opacity: 0.4;
  }
}

.bgChangeColor {
  animation: bgChange 3s infinite alternate-reverse;
  display: flex;
}

@keyframes bgChange {
  0% {
    background-color: #12232e;
  }
  100% {
    background-color: #1b3344;
  }
}

.MuiPaper-root > .MuiList-root {
  background-color: #1b3344;
  border-radius: 0px !important;
  border: 1px solid #FFF3;
  box-shadow: none !important;
  overflow-x: hidden;
}

.centered {
  text-align: center;
}

.MuiMenuItem-root {
  color: #fff !important;
  border-bottom: 1px solid #fff3 !important;
}

.MuiList-root {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}


h1,h2,h3 {
  font-family: RobotoThin;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url(./fonts/Roboto-Regular.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'RobotoThin';
  src: local('RobotoThin'), url(./fonts/Roboto-Thin.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

.NavBar {
  box-shadow: none !important;
}


.PageButton {
  letter-spacing: 0.9px !important;
}

.WhoTitle {
  color: #4DA8DA;
  letter-spacing: 2px;
  font-family: 'RobotoThin';
}

.noSelect {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.noSelect:focus {
  outline: none !important;
}


.CheckIconColor {
  color: #4DA8DA !important;
}

.lightBlueText {
  color: #4DA8DA;
}


.linkedIn, .linkedIn:visited, .linkedIn:hover, .linkedIn:active {
  color: inherit;
}

.footerLink {
  color: #FFF;
  text-decoration: none;
  transition: 0.5s;
}

.footerLink:hover {
  color: #4DA8DA;
  text-decoration: none;
  transition: 0.5s;
}

/* Home Page Rotation */
@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.rotating {
  -webkit-animation: rotating 45s linear infinite;
  -moz-animation: rotating 45s linear infinite;
  -ms-animation: rotating 45s linear infinite;
  -o-animation: rotating 45s linear infinite;
  animation: rotating 45s linear infinite;
}

.imageBase {
  max-width: 371px;
  min-height: 350px;
}


.whatImage {
  z-index: 9;
  max-width: 371px;
  min-height: 350px;
}

.whatImageContainer {
  z-index: 1;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden !important;
  padding-bottom: 100px;
}

.logoDynamic {
  padding: 20px;
  width: 200px;
}

@media (max-width: 480px) {
  .logoDynamic {
    width: 150px !important;
  }

  .logoDynamicFooter {
    width: 200px !important;
    height: 86px !important;
  }

  .dynImageWhat {
    width: 250px;
    height: 230px; 
  }

  .dynHeader {
    font-size: 1.2em !important;
  }


  .whatImage {
    position: fixed;
    top: 200px;
    left: 150px;
  }
}


.divideranim {
  animation: anim 3s infinite alternate;
  min-height: 50px;
  margin-top: 50px;
  margin-bottom: 50px;
  width: 100vw;
  left: 0;
  position: absolute;
}



@keyframes anim {
  0% {
    background-color: #fff2;
  }

  100% {
    background-color: #12232e;
  }
}

.fadeinout {
  animation: fadeinout 45s linear infinite;
}

@keyframes fadeinout {
  0% {
    opacity: 1;
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.dynBoxHeight {
  height: 100%;
}

/* mobile media bg image */
@media only screen and (max-width: 600px) {
  .dynHeight {
    min-height: 300px;
  }
  .dynBoxHeight {
    height: 150px;
  }
}



.paperBG {
  background-color: #bfd1d8!important;
}

.toolLabel {
  color: #81b1d3;
}

.adminLabel {
  color: #fff!important;
  box-shadow: 0 0 4px 4px #fff9!important;
  background-color: #12232e!important;
}

.darkLabel {
  color: #12232e;
  margin: 5px;
  margin-right: 10px;
  text-transform: uppercase;
  border-bottom: 1px solid #12232e22;
}

.tabPanel {
  background-color: #ffffff;
  color: #000;
  box-shadow: 0 2px 2px 2px rgba(0, 0, 0, 0.6);
}


.loadingIconContainer {
  height: 50px;
  width: 50px;
  position: fixed;
  right: 0;
  bottom: 0;
  margin-right: 10px;
  margin-bottom: 10px;
  animation: rotating 1s infinite;
}

@keyframes rotating {
    0% {
      transform: rotate(360deg);
    }

    100% {
      transform: rotate(0deg);
    }
}

/* flex row */
.customRow {
  display: flex;
  justify-content: space-between;
}

.bold {
  font-weight: bold;
}


.projectDivider {
  height: 3px;
  background-color: #4DA8DA;
  border-bottom: 2px solid #1b3344;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
}



.qrButton {
  background-color: #0059ff !important;
  color: #fff !important;
  width: 250px;
}

.qrText {
  background-color: #fff !important;
}


.gridBoxInternal {
  text-align: center;
  font-size: 0.9rem;
  margin: 10px;
  justify-content: center;
  align-items: center;
  display: flex;
  box-shadow: 0 0 2px 2px #fff3;
  min-height: 100px;
  transition: 0.5s;
  border-radius: 5px;
  background-color: #12232e;
}

.gridBoxInternal:hover {
  box-shadow: 0 0 2px 2px #4DA8DA;;
  cursor: pointer;
  animation: test 1s infinite alternate;
}

@keyframes test {
  0% {
    box-shadow: 0 0 2px 5px #4DA8DA99;
  }

  100% {
    box-shadow: 0 0 2px 2px #fffa;
    transform: scale(1.05); 
  }
}

.destyle {
  text-decoration: inherit;
  color: inherit;
}



/* Overlay animation */
.overlayHome {
  left: -100px;
  top: -100px;

  z-index: 1;
  position: absolute;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  transform: translate(-50%, -50%);
  animation: overlayAnimation 1s infinite alternate-reverse;
  
}

/* Overlay animation */
.overlayTL {
  z-index: 1;
  position: absolute;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  transform: translate(-50%, -50%);
  animation: overlayAnimation 1s infinite alternate-reverse;
}



@keyframes overlayAnimation {
  0% {
    box-shadow: 0 0 40px 80px #fff1;
    background-color: #fff1;
  }
  100%{
    box-shadow: 0 0 40px 80px #4DA8DA66;
    background-color: #4DA8DA66;
  }
}




.customButton {
  z-index: 999;
  text-transform: uppercase; 
  letter-spacing: 2px;
  background-color:transparent;
  border-radius: 0; 
  border: 1px solid #FFF3; 
  padding-left: 15px;
  margin-bottom: 30px;
  padding-right: 15px;
  color: white;
  display: block; 
}




.tlHover {
  transition: 0.5s;
}

.tlHover:hover {
  background-color: #000;
  cursor: pointer;
  transition: 0.5s;
  box-shadow: 0 0 2px 2px #999;
  transform: scale(1.03);
}

.tlDialog {
  background-color: #1b3344;
  color: #fff;
}